<template>
    <div>
        <div @click="visible=!visible" class="classic-border pad-top-10 margin-bottom-10 hidden-xs">
            <h6 class="margin-0">Report this Ad?</h6>
        </div>
        <sn-modal
            title="Report Ad"
            :show="visible"
            @close="visible = false"
            :custom-close="true"
            :width="'35%'"
        >
            <template v-slot:body>
                <div class="row marigin-0">
                    <div class="col-12">
                        <sn-observer ref="reportForm">
                            <sn-input
                                data-input-reason
                                type="text"
                                :rules="['required']"
                                label="Reason"
                                name="Reason"
                                :value="payload.reason"
                                v-model="payload.reason"
                                :oerror="error.reason"
                                :showLabel="true"
                            />
                            <sn-input
                                data-input-comment
                                style="height: 100px"
                                type="textarea"
                                :rules="['required']"
                                label="Comments"
                                name="Comments"
                                :value="payload.comment"
                                v-model="payload.comment"
                                :oerror="error.comment"
                                :showLabel="true"
                            />
                        </sn-observer>
                    </div>
                    <div class="col-12 pad-top-10 text-center">
                        <button class="btn btn-sm btn-primary" @click="reportAd()">Submit</button>
                    </div>
                </div>
            </template>
        </sn-modal>
    </div>
</template>
<script>
export default {
    props: ['product'],
    data() {
        return {
            visible: false,
            error: {},
            payload: {
                reason: null,
                comment: null
            }
        }
    },
    methods: {
        reportAd() {
            this.$refs.reportForm.validate();
        }
    }
}
</script>
<style lang="stylus" scoped>
.classic-border {
//   border: 1px solid #002f4444;
  border-radius: 4px;
  cursor pointer
  h6 {
    text-decoration: underline;
    text-decoration-style: dashed;
  }
}
</style>