<template>
  <div class="eb-product-details-page">
    <div class="row margin-0 justify-content-center">
      <div class="col-12 pad-0">
        <div v-if="loading" class="row margin-0">
          <div class="col-md-8 col-12 pad-0">
            <div class="preview-img-skel" style="padding: 0px">
              <div
                class="preview-img-block pad-10"
                style="background: transparent"
              >
                <div
                  class="img-fluid preview-img shine"
                  style="height: 40vh; width: 100%"
                ></div>
              </div>
            </div>
            <div class="preview-img-skel hidden-xs">
              <span class="s-head bold shine" style="max-width: 40%"></span>
              <p class="txt-grey shine" style="max-width: 80%"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
            </div>
          </div>
          <div class="col pad-0">
            <div class="preview-detail-skel">
              <h5
                class="margin-bottom-10 shine pad-10"
                style="max-width: 30%"
              ></h5>
              <h4
                class="margin-bottom-10 shine pad-5"
                style="max-width: 70%"
              ></h4>
              <span
                class="txt-grey d-block shine margin-bottom-10"
                style="font-size: 0.7em"
              ></span>
            </div>
            <div class="preview-detail-skel">
              <span class="s-head shine pad-5" style="max-width: 45%"></span>
              <p class="bold margin-bottom-10 pad-5 shine"></p>
              <span class="s-head shine pad-5" style="max-width: 45%"></span>
              <p class="light-weight margin-bottom-5 shine"></p>
              <hr />
              <div class="d-block">
                <ul class="ct-list">
                  <li>
                    <div>
                      <a class="lk shine"></a>
                    </div>
                  </li>
                  <li>
                    <div>
                      <a class="lk shine"></a>
                    </div>
                  </li>
                  <li>
                    <div>
                      <a class="lk shine"></a>
                    </div>
                  </li>
                  <li>
                    <div>
                      <a class="lk shine"></a>
                    </div>
                  </li>
                </ul>
              </div>
              <span class="txt-grey shine"></span>
            </div>
            <div class="preview-img-skel visible-xs">
              <span class="s-head bold shine" style="max-width: 40%"></span>
              <p class="txt-grey shine" style="max-width: 80%"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
              <p class="txt-grey shine"></p>
            </div>
          </div>
        </div>
        <div v-else class="row margin-0">
          <div class="col-12 pad-0">
            <div class="row margin-0 eb-card">
              <div :class="$width > $devices.maxTab?'col-8 pad-0':'col-12 pad-0'">
                <div class="preview-img-skel" style="padding: 0px">
                  <div class="preview-img-block">
                    <span class="featured-adb" v-if="product.featured"
                      >Featured</span
                    >
                    <span class="index-count" v-if="product.images.length > 0"
                      >{{ selected_index + 1 }}/{{ product.images.length }}</span
                    >
                    <img
                      :src="preview_image ? preview_image : '/noimage.jpg'"
                      class="img-fluid preview-img"
                      alt=""
                    /><span class="fa fa-chevron-right f-right"></span>
                  </div>
                  <div>
                    <ul class="thumb-list">
                      <template v-for="(item, index) in images">
                        <li
                          @click="
                            preview_image = item;
                            selected_index = index;
                          "
                          :key="index"
                          :class="{ active: preview_image === item }"
                        >
                          <img :src="item" alt="" />
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
              <div :class="$width > $devices.maxTab?'col-4 pad-0':'col-12 pad-0'">
                <div class="preview-detail-skel">
                  <ad-details :item="product" :tag="['view-page']"></ad-details>
                  <location-detail :item="product"></location-detail>
                </div>
                <div class="preview-detail-skel">
                  <h4>Seller Details</h4>
                  <div class="seller-info">
                    <div class="seller-img">
                      <img src="/user.png" class="img-fluid" alt="" />
                    </div>
                    <div class="seller-details">
                      <div>
                        <p>{{ product.user.name }}</p>
                        <span
                          >Member since
                          {{
                            new Date().toDateString(product.user.created_on)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="direction-tag">
                      <span
                        @click="$router.push(`/store/${product.user.path}/`)"
                        class="fa fa-chevron-right"
                      ></span>
                    </div>
                  </div>
                  <div class="preview-number">
                    <span class="fa fa-mobile"></span>
                    <span> +91 {{(mobile && mobile.mobile)?mobile.mobile:'XXXXXXXXXX'}} </span>
                    <span v-if="profile && profile.mobile" class="show" @click="(mobile && mobile.mobile)?mobile=null:getNumber()"> {{(mobile && mobile.mobile)?'Hide Number':'Show Number'}}</span>
                    <span v-else class="show" @click="$store.dispatch('requestLogin')"> {{(mobile && mobile.mobile)?'Hide Number':'Show Number'}}</span>
                  </div>
                  <hr />
                  <div class="d-block">
                    <ul class="ct-list">
                      <li>
                        <div>
                          <label class="lk" @click="openLink('call')"
                            >
                            <fal-icon
                              name="phone"
                              variant="fas"
                              size="1x"
                              style="font-size: 0.7rem;top: -1px; position: relative"
                            />
                          </label>
                        </div>
                        <span>Call</span>
                      </li>
                      <!-- <li>
                        <div>
                          <label class="lk" @click="openLink('whatsapp')"
                            ><ion-icon name="logo-whatsapp"></ion-icon
                          ></label>
                        </div>
                        <span>Whatsapp</span>
                      </li> -->
                      <li>
                        <div>
                          <label class="lk" @click="openLink('chat')"
                            >
                            <fal-icon
                              name="comment-alt-dots"
                              variant="fas"
                              size="1x"
                              style="font-size: 0.7rem;top: -1px; position: relative"
                            />
                            </label>
                        </div>
                        <span>Chat</span>
                      </li>
                      <li>
                        <div>
                          <label class="lk" @click="copyLink(product)"
                          >
                          <fal-icon
                            name="share"
                            variant="fas"
                            size="1x"
                            style="font-size: 0.7rem;top: -1px; position: relative"
                          />
                          </label>
                        </div>
                        <span>Share</span>
                      </li>
                    </ul>
                  </div>
                  <span class="txt-grey"
                    ><b>Note:</b> Please follow saftey measures while you are
                    contacting with seller</span
                  >
                  <report-ad :product="product" />
                </div>
                <!-- <div class="pad-5">
                  <my-ad adtype="DHRF" />
                </div> -->
              </div>
            </div>
            <div class="row margin-0 eb-card">
              <div class="col-12 pad-10">
                <h4>Description</h4>
                <p class="txt-grey">
                  {{ product.description }}
                </p>
              </div>
            </div>
            <my-ad :adtype="'DSQ'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LocationDetail from "@/components/LocationDetail";
import AdDetails from "@/components/AdDetails";
import ReportAd from "./components/ReportAd";
import TimeLabel from "@/components/TimeLabel.vue";
import { mapState } from "vuex";
export default {
  components: {
    TimeLabel,
    AdDetails,
    LocationDetail,
    ReportAd
  },
  props: ["id"],
  data() {
    return {
      images: [],
      preview_image: null,
      product: null,
      mobile: null,
      loading: true,
      visible_contact: false,
      selected_index: 0,
      number: "XXXXXXXXXX",
      action: null,
    };
  },
  created() {
    this.getProduct();
  },
  computed: {
    windowRef: function() {
      if(window) {
        return window;
      }
      return null;
    }
  },
  watch: {
    profile: function () {
      if (this.profile && this.action) {
        this.openLink(this.action);
      }
    },
  },
  methods: {
    getProduct() {
      this.loading = true;
      this.$cs.product
        .list({
          resource: `${this.$cs.product.api}${this.id}/`,
        })
        .then((res) => {
          this.product = res.data;
          this.images = this.product.images;
          if (this.images.length > 0) {
            this.preview_image = this.images[0];
          }
          this.loading = false;
        });
    },
    getNumber() {
      this.$cs.myproduct
        .list({
          resource: `${this.$cs.myproduct.api}${this.id}/mobile/`,
        })
        .then((res) => {
          this.mobile = res.data;
        });
    },
    openLink(type) {
      if (this.profile === null) {
        this.$store.dispatch("requestLogin");
        this.action = type;
        return;
      }
      const number = window.atob(this.product.hash);
      let url = "";
      switch (type) {
        case "call":
          url = `tel:+91${number}`;
          window.open(url, "_blank").focus();
          break;
        case "whatsapp":
          url = `https://wa.me/91${number}?text=Hi,%20I%20am%20Interested%20to%20buy%20your%20product.%20Is%20it%20available?%0Ahttps://ebechana.com/view/${this.product.id}/`;
          window.open(url, "_blank").focus();
          break;
        case "chat":
          this.$router.push(`/chat/${this.product.id}`);
          break;
        case "show":
          this.number = number;
          break;
      }
      this.action = null;
    },
    copyLink(item) {
      let newVariable;
      newVariable = window.navigator;
      if (newVariable.share) {
        newVariable
          .share({
            title: "EBechana",
            text: "Dont miss this wonderful chance. View this offer now.",
            url: `https://ebechana.com/view/${item.id}`,
          })
          .then(() => this.cs.message("You shared this ad sucessfully"))
          .catch((error) => this.cs.message("Unable to share this ad"));
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.eb-product-details-page {
  padding: 2vh 0;
  min-height: 92vh;

  h5 {
    margin: 0 0 16px;
    font-weight: 700;
    font-size: 20px;
    color: #002f34;
  }
}



.classic-border {
  border: 1px solid #002f4444;
  border-radius: 4px;
}

.preview-img-skel {
  border-right: 1px solid #002f4444;
  padding: 10px;
  // margin-right: 10px;
  // border-radius: 4px;
  // margin-bottom: 10px;
  background-color: #ffffff;
}

.preview-detail-skel {
  // border: 1px solid #002f4444;
  padding: 10px;
  margin-bottom: 10px;
  // border-radius: 4px;

  h6 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #002f34;
    font-weight: 400;
    padding: 0;
  }
}

.preview-img-block {
  height: 55vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  // background-color: #000;
  position: relative;

  .preview-img {
    max-height: 40vh;
  }

  .index-count {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #eeeeee;
    font-weight: 400;
  }

  .featured-adb {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.thumb-list {
  list-style: none;
  margin: 0px 5px;
  padding: 0px;
  // width 100%
  // max-width 100%
  display: flex;
  overflow: auto;
  border-top: 1px solid #ddd;

  li {
    min-width: 5vw;
    width: 5vw;
    height: 5vw;
    max-height: 5vw;
    border: 1px solid #ddd;
    margin: 10px 5px;
    float: left;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.active {
      border-color: #154764;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.eye-outline {
  color: #0594d9;
  cursor: pointer;
}

.btn-whatsapp {
  background-color: #31bf66;
  color: white;
}

.btn-call {
  background-color: #00699c;
  color: white;
}

.seller-info {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0px 0px;

  .seller-img {
    width: 6vw;
    text-align: center;

    img {
      max-width: 4vw;
      max-height: 4vw;
      border-radius: 50%;
    }
  }

  .seller-details {
    width: 76%;
    display: flex;
    align-items: center;

    p {
      font-size: 1em;
      color: #002f34;
      text-transform: capitalize;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      margin-bottom: 0px;
    }

    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .direction-tag {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .view {
    padding-top: 2vh;
  }

  .preview-img-skel {
    margin-right: 0px;
  }

  .preview-img-block {
    height: 35vh;

    .preview-img {
      max-height: 30vh;
    }
  }

  .thumb-list {
    li {
      width: 13vw;
      height: 13vw;
      max-height: 10vw;
      margin: 10px 5px;
    }
  }

  .seller-info {
    .seller-img {
      width: 10vw;
      text-align: center;

      img {
        max-width: 8vw;
        max-height: 8vw;
        border-radius: 50%;
      }
    }

    .seller-details {
      width: calc(85% - 15vw);
    }
  }
}

.ct-list {
  list-style: none;
  margin: 0px 0px 0px -10px;
  padding: 0px;
  display: flex;
  justify-content: space-evenly;

  li {
    float: left;
    padding: 10px;
    text-align: center;
    min-width: 5%;

    a, label {
      display: inline-block;
      background-color: #dedede;
      border-radius: 50%;
      margin-bottom: 3px;
      padding: 0.6em;
      height: 2.5em;
      width: 2.5em;
      text-align: center;
      cursor: pointer;
    }

    ion-icon {
      height: 1.3em;
      width: 1.3em;
      color: #000000;
      background: transparent;
      // position: relative;
      // top: 1px;
    }

    span {
      font-size: 0.8em;
      font-weight: 600;
      letter-spacing: 0.3px;
    }
  }
}

.preview-number {
  padding: 10px;
  text-align: center;

  .show {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
@media (max-width: 400px) {
  .preview-img-skel {
    border: none;
  }
}
</style>
